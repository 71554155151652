import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getConsignmentId } from "../redux/Settings/Line/lineSettingsSelectors";
import { getSelectedConsignment } from "../redux/Line/lineSelectors";
import { getStatsForIncorrectlyStoppedConsigment } from "./functions";
import {
  resetLine,
  setLineStats,
  setPreloadedStats,
  setProductionStats,
} from "../redux/Line/lineActionCreators";
import { getLineStatus, getPreloadedStats } from "../redux/Line/lineSelectors";
import { getLineSettings } from "../redux/Settings/Line/lineSettingsSelectors";

export const useHandleIncorrectlyStoppedLine = (numberPackagesInAggregates) => {
  const consignmentId = useSelector(getConsignmentId);
  const consignment = useSelector(getSelectedConsignment);
  const [preloadedStats, setStats] = useState(null);
  const lineInfo = useSelector(getLineSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    const stats = getStatsForIncorrectlyStoppedConsigment(
      consignment,
      numberPackagesInAggregates
    );

    setStats(stats);
  }, [consignment, consignmentId, dispatch, numberPackagesInAggregates, lineInfo.status]);

  useEffect(() => {
    if (
      consignmentId &&
      preloadedStats?.item?.total &&
      !!lineInfo.status
    ) {
      dispatch(setLineStats({ ...preloadedStats?.item, level: 0 }));
      dispatch(setLineStats({ ...preloadedStats?.box, level: 1 }));
      dispatch(setLineStats({ ...preloadedStats?.pallet, level: 2 }));
      dispatch(setProductionStats({ products: preloadedStats?.item?.valid, boxes: preloadedStats?.box?.total, pallets: preloadedStats?.pallet?.total, totalWeight: preloadedStats?.item?.totalWeight }));

      console.log(preloadedStats, "preloadedStats");
    } else if (!consignmentId) {
      dispatch(resetLine());
    }
  }, [consignmentId, dispatch, preloadedStats, lineInfo.status]);
};
