import API from "./APIService";

class SettingsAPI {
  getLines = () => API.get(`/api/productionlines/all/`);
  getSelectedLine = () => API.get(`/api/productionlines/SelectedLine/`);
  getUpdatedProducts = (id) =>
    API.get(`/api/productionline/ActualProductList/${id}`);
  getConsignmentsByLineId = (lineId) =>
    API.get(`/api/Consignment/all/${lineId}`);
  changeSelectedLine = (lineId) =>
    API.put(`/api/ProductionLines/SelectedLineChange/${lineId}`);
  getAggregationIncludedInfo = () =>
    API.get("/api/auth/CheckModuleAvaible/?moduleName=Aggregation");
  save = (settings) => {
    return API.put(`/api/productionlines/UpdateTechnicalSettings/`, settings);
  };
  getIsClient = () =>
    API.get("/api/auth/CheckModuleAvaible/?moduleName=Client");

  getIsPartNumber = () =>
    API.get("/api/auth/CheckModuleAvaible/?moduleName=ShowPartNumber");

  getIsSerialNumber = () =>
    API.get("/api/auth/CheckModuleAvaible/?moduleName=NitaSerialNumber");

  getShouldShowDemoMode = () =>
    API.get("/api/Auth/CheckModuleAvaible?moduleName=demo");

  changeSelectedProduct = (lineId, productId, consignmentId) =>
    API.put(
      `/api/productionlines/ChangeProduct?lineId=${lineId}&productId=${productId}${
        consignmentId ? `&consignmentId=${consignmentId}` : ""
      }`,
      {}
    );
}

export default new SettingsAPI();
