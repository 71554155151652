import axios from "axios";
import { handleErrorFunc } from "../App";
import { LOCAL_STORAGE_DATA_NAME } from "../constants";
import { errorHandlerByStatus } from "./core";

class API {
  constructor(localStorageData = LOCAL_STORAGE_DATA_NAME) {
    const baseUrlFromLS = localStorage.getItem("BASE_URL");
    console.log("baseUrlFromLS", baseUrlFromLS);
    this.url = baseUrlFromLS;
    // this.url = baseUrlFromLS || process.env.REACT_APP_BASE_URL || "";
    this.localStorageData = localStorageData;
  }

  handleSuccess = (response) => {
    const res = response.data;

    console.log("from handleSuccess res", res);
    if (res.statusCode && res.statusCode !== 200) {
      console.log("handleSuccess fired", res.statusCode);
      this.handleError(res);
    }

    return response;
  };

  handleError = (error) => {
    console.log("from handleError", error.toString().includes("Network"));
    if (error.toString().includes("Network")) {
      errorHandlerByStatus(503, handleErrorFunc);
    }
    if (!!error.response?.status) {
      if (error.response.status === 401) {
        handleErrorFunc(401, "");
      } else {
        errorHandlerByStatus(error.response.status, handleErrorFunc);
      }
    } else if (!!error?.statusCode && !!error?.message) {
      handleErrorFunc(error.statusCode, error.message);
    }

    return Promise.reject(error);
  };

  create = (headers) => {
    const localStorageData = JSON.parse(
      localStorage.getItem(this.localStorageData)
    );
    const token = localStorageData?.accessToken;
    const sessionId = window.sessionStorage.getItem("X-MARK-SESSION-ID");
    const headerAuth = token && {
      Authorization: token ? `Bearer ${token}` : "",
    };
    const customIdSessionHeader = !!sessionId && {
      "X-MARK-SESSION-ID": sessionId,
    };
    const service = axios.create({
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...headers,
        ...headerAuth,
        ...customIdSessionHeader,
      },
    });

    service.interceptors.response.use(this.handleSuccess, this.handleError);

    // service.interceptors.request.use((request) => {
    //   // console.log("Starting Request", request);
    //   return request;
    // });

    // service.interceptors.response.use((response) => {
    //   return response;
    // });

    return service;
  };

  get = async (path = "", isSelected, headers) => {
    const service = this.create(headers);

    return service
      .request({
        method: "GET",
        url: `${this.url}${path}`,
      })
      .then((res) => res?.data)
      .catch((err) => this.handleError(err));
  };

  post = (path = "", data = {}, headers) => {
    const service = this.create(headers);

    return service
      .request({
        method: "POST",
        url: `${this.url}${path}`,
        data,
      })
      .then((res) => res?.data)
      .catch((err) => this.handleError(err));
  };

  put = (path = "", data = {}, headers) => {
    const service = this.create(headers);
    return service
      .request({
        method: "PUT",
        url: `${this.url}${path}`,
        data,
      })
      .then((res) => res?.data)
      .catch((err) => this.handleError(err));
  };

  delete = (path = "", headers) => {
    const service = this.create(headers);

    return service
      .request({
        method: "DELETE",
        url: `${this.url}${path}`,
      })
      .then((res) => res?.data)
      .catch((err) => this.handleError(err));
  };

  log = (service) => {
    service.interceptors.request.use((request) => {
      console.log("Starting Request", request);
      return request;
    });

    service.interceptors.response.use((response) => {
      console.log("Response:", response);
      return response;
    });
  };
}

export default new API();
