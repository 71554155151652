import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getProductionStats, getPreloadedStats, getLineStatus } from '../../../../../../redux/Line/lineSelectors'
import s from './ProductionStats.module.scss'
import { getIsAggregationIncluded } from '../../../../../../redux/App/appSelectors'

export const ProductionStats = ({ isConsignment }) => {
    const productionStats = useSelector(getProductionStats)
    const isAggregationIncluded = useSelector(getIsAggregationIncluded);
    const lineStatus = useSelector(getLineStatus)

    const [productsToShow, setProducts] = useState(0)
    const [boxesToShow, setBoxes] = useState(0)
    const [palletsToShow, setPallets] = useState(0)
    const [weightToShow, setWeight] = useState(0)

    useEffect(() => {
        setProducts(productionStats?.products || 0)
        setBoxes(productionStats?.boxes || 0)
        setPallets(productionStats?.pallets || 0)
        setWeight(productionStats?.totalWeight || 0)
    }, [lineStatus, productionStats])

    return (
        <div className={clsx(s.production, !isConsignment && s.large)}>
            <div>
                <div className={s.row}>
                    <span className={s.key}>Ед. продукции:</span>
                    <span className={s.value}>{`${productsToShow} шт`}</span>
                </div>
                {isAggregationIncluded &&
                    <>
                        <div className={s.row}>
                            <span className={s.key}>Короба</span>
                            <span className={s.value}>{`${boxesToShow} шт`}</span>
                        </div>
                        <div className={s.row}>
                            <span className={s.key}>Паллеты:</span>
                            <span className={s.value}>{`${palletsToShow} шт`}</span>
                        </div>
                    </>
                }
                <div className={s.row}>
                    <span className={s.key}>Общий вес:</span>
                    <span className={s.value}>{`${weightToShow} кг`}</span>
                </div>
            </div>
        </div>
    )
}
