export const ERROR_STATUSES = [
  "NOREAD",
  "NOFOUND",
  "DUPLICATE",
  "PRODUCT",
  "MASK",
  "SUPERFLUOUS",
  "EXPIRED",
  "DROPOUT",
  "NETWORK",
  "TIMELIMIT",
];
export const capacity = 100;

export const LOCAL_STORAGE_DATA_NAME = "uplineOperatorData";

export const consignmentCompleteIcon = require("./assets/img/icons/check.png");
export const deleteIcon = require("./assets/img/icons/delete.png");

export const codeStatuses = {
  INITIAL: "initial",
  VALID: "valid",
  INVALID: "invalid",
  NOREAD: "noread",
  DROPOUT: "dropout",
};

export const displayModes = {
  ITEMS: 1,
  BOXES: 2,
  PALLETS: 3,
};

export const productLevels = {
  PRODUCT: 0,
  BOX: 1,
  PALLET: 2,
  SET: 10,
};

export const accentColor = "#5589FF";

export const FULL_ACCESS = 7;
export const OPERATOR_ACCESS = 1;
export const NO_ACCESS = 0;

export const ACCESS_LEVELS = {
  0: FULL_ACCESS,
  1: FULL_ACCESS,
  2: OPERATOR_ACCESS,
  3: NO_ACCESS,
  4: NO_ACCESS,
};

export const printingModeList = {
  AUTO: "На линии автоматически",
  PREPRINT: "Предварительная печать",
  MANUALLY: "На линии вручную",
  TYPOGRAPHY: "В типографии",
};
