import clsx from "clsx";
import React, { memo, useEffect, useState } from "react";
import g from "../../../../assets/styles/Main.module.scss";
import s from "./Confirm.module.scss";
import LineAPI from "../../../../api/LineAPI";
import { useSelector } from "react-redux";
import { getLineSettings } from "../../../../redux/Settings/Line/lineSettingsSelectors";
import SettingsAPI from "api/SettingsAPI";
import { validate, validateField } from "functions";
import Input from "components/Common/Input/Input";
import { getLineStatus } from "redux/Line/lineSelectors";

const Confirm = ({
  text,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  disabled,
  onClose,
  children,
  isLoading = false,
}) => {
  return (
    <>
      <div className={s.confirm}>
        <p className={s.text}>{text}</p>
        {children}
        <div className={s.controls}>
          {!isLoading && (
            <>
              {agreeText && (
                <button
                  type="button"
                  onClick={onAgree}
                  disabled={disabled}
                  className={clsx(s.btn, s.btnAgree)}
                >
                  {agreeText}
                </button>
              )}
              <button
                type="button"
                onClick={onDisagree}
                className={clsx(s.btn, s.btnDisagree)}
              >
                {disagreeText}
              </button>
            </>
          )}
        </div>
      </div>
      <div className={g.overlay} onClick={isLoading ? null : onClose} />
    </>
  );
};

export const ConfirmLine = memo(({
  text,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
  disabled,
  onClose,
  children,
}) => {
  const [preStartsSettings, setPreStartSettings] = useState(null);
  const lineSettings = useSelector(getLineSettings);
  const [isPartNum, setIsPartNum] = useState(false);
  const [isSerialNumber, setIsSerialNumber] = useState(false);
  const [partNum, setPartNum] = useState("");
  const [errorToggle, setErrorToggle] = useState(false);
  const lineStatus = useSelector(getLineStatus);


  const onPartNumnChange = (e, name) => {
    const value = e.target.value;
    setPartNum(value);
  };

  const getInfo = async () => {
    try {
      const res = await LineAPI.getPreStartLineInfo(lineSettings?.id);
      console.log(res);
      if (res?.productName || res?.consignmentNumber) {
        setPreStartSettings(res);
      } else {
        setPreStartSettings(null);
        onDisagree();
      }
    } catch (e) {
      setPreStartSettings(null);
      onDisagree();
    }
  };
  useEffect(() => {
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineSettings?.id]);

  useEffect(() => {
    const getIsPartNum = async () => {
      try {
        const resSerialNumber = await SettingsAPI.getIsSerialNumber();
        // const resSerialNumber = true;
        const res = await SettingsAPI.getIsPartNumber();
        setIsSerialNumber(resSerialNumber);
        setIsPartNum(res);

      } catch (e) {
        console.log(e);
      }
    };
    getIsPartNum();
    setPartNum(localStorage.getItem('partNumber'))
  }, []);

  const handleAgree = () => {
    localStorage.setItem('partNumber', partNum);
    onAgree(partNum)
  }

  return (
    <>
      {preStartsSettings && (
        <div
          className={
            lineStatus == 'PAUSE'
              ? s.confirmLine
              : isPartNum
                ? s.confirmProductWpartNum
                : s.confirmProduct
          }
        >
          <div className={s.modalHeader}>Подтверждение запуска линии</div>
          <div className={s.modalContent}>
            <div className={s.commonContent}>
              {Object.entries(text).map((line) => (
                <p className={s.textRow} key={line.id}>
                  <span className={s.boldText}>{line[0]}</span>
                  <span className={s.text}>{line[1]}</span>
                </p>
              ))}
            </div>

            {lineStatus != 'PAUSE' && (
              <div className={s.productContent}>
                <p className={s.boldText}>
                  При закрытии задания будет установлена дата производства:
                </p>
                <div className={s.childrenContainer}> {children} </div>
                <p className={s.productText}>
                  При необходимости Вы можете поменять дату на необходимую Вам.
                </p>
                {!isSerialNumber ? (
                  isPartNum && (
                    <div className={s.isPartNumContainer}>
                      <p className={s.boldText}>Номер партии:</p>
                      <input

                        className={s.partuNumInput}
                        value={partNum}
                        // error={lineErrors.has("allowedNumberErrors")}
                        name="signal-more"
                        type="text"
                        onChange={onPartNumnChange}
                        containerStyles={s.containerStyles}
                      />
                    </div>
                  )
                ) : (
                  <div className={s.isPartNumContainer}>
                    <p className={s.boldText}>Серия:</p>
                    <Input
                      // className={s.partuNumInput}
                      value={partNum}
                      name="serial-number"
                      type="text"
                      onChange={onPartNumnChange}
                      inputStyles={s.partuNumInput}
                      error={errorToggle}
                      setError={setErrorToggle}
                      errorText={"Обязательное поле"}
                      validate={true}
                    />
                    {/* {!confirmErrors.has('serial-number') && (
                      <span className={g.errorMessageText}>Обязательное поле</span>
                    )} */}
                  </div>
                )}
                <p className={s.productText}>
                  Количество свободных кодов маркировки <span style={{color:'red'}}>{preStartsSettings?.markingCodesRemainder}</span>, если Вы сомневаетесь, что Вам хватит данного количества для выполнения смены, то сообщите административному персоналу для дополнительного заказа.
                </p>
              </div>
            )}
          </div>
          <div className={s.controls}>
            <button
              type="button"
              onClick={!errorToggle ? () => handleAgree() : null}
              className={clsx(s.btn, s.btnAgree)}
            >
              {agreeText}
            </button>
            <button
              type="button"
              onClick={onDisagree}
              className={clsx(s.btn, s.btnDisagree)}
            >
              {disagreeText}
            </button>
          </div>
        </div>
      )}
      <div className={g.overlay} onClick={onClose} />
    </>
  );
});
export default Confirm;
