export const errorHandlerByStatus = (errorStatus, errorHandler) => {
  console.log("from errorHandlerByStatus", errorStatus);
  switch (errorStatus) {
    case 400:
      errorHandler(
        errorStatus,
        "Произошел неверный запрос. Обратитесь в службу поддержки"
      );
      break;
    // case 401:
    //   errorHandler(
    //     errorStatus,
    //     "К сожалению, вам отказано в доступе. Возможно вы не авторизованы. Попробуйте авторизоваться или обратитесь в службу поддержки"
    //   );
    //   break;
    case 403:
      errorHandler(
        errorStatus,
        "К сожалению, вам отказано в доступе. Обратитесь в службу поддержки"
      );
      break;
    case 404:
      errorHandler(
        errorStatus,
        "К сожалению, запрашиваемая страница не найдена. Возможно, вы перешли по ссылке, в которой была допущена ошибка, или ресурс был удален"
      );
      break;
    case 500:
      errorHandler(
        errorStatus,
        "Внутренняя ошибка сервера. Обратитесь в службу поддержки"
      );
      break;
    case 503:
      errorHandler(errorStatus, "Отсутствует соединение с сервером");
      break;
    default:
      return;
  }
};
