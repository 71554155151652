import clsx from "clsx";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { codeStatuses, displayModes } from "../../../../constants";
import { getSelectedDisplayMode } from "../../../../redux/App/appSelectors";
import {
  getBoxesStats,
  getBoxesTable,
  getPalletsStats,
  getPalletsTable,
  getPreloadedStats,
  getProductsStats,
  getProductsTable,
  getSetsTableItems,
} from "../../../../redux/Line/lineSelectors";
import Radio from "../../../Common/Radio/Radio";
import TableRow from "./components/TableRow/TableRow";
import "./ResultsTable.module.scss";
import s from "./ResultsTable.module.scss";
import { FixedSizeList as List } from "react-window";
import { getLineStatus } from "../../../../redux/Line/lineSelectors";

const ResultsTable = ({ resetTableFuncs, showPhotos }) => {
  const selectedDisplayingMode = useSelector(getSelectedDisplayMode);
  const productsTableItems = useSelector(getProductsTable);
  const boxesTableItems = useSelector(getBoxesTable);
  const palletsTableItems = useSelector(getPalletsTable);
  const productsStats = useSelector(getProductsStats);
  const boxesStats = useSelector(getBoxesStats);
  const palletsStats = useSelector(getPalletsStats);
  const [stats, setStats] = useState({});
  const [tableItems, setTableItems] = useState([]);
  const [checked, setChecked] = useState("all");
  const lineStatus = useSelector(getLineStatus);
  const setsTableItems = useSelector(getSetsTableItems);
  const [lastAddedItem, setLastAddedItem] = useState(null);

  const tabsInfo = useMemo(
    () => {
      const baseInfo = {
        [displayModes.ITEMS]: {
          stats: { ...productsStats },
          table: [...productsTableItems, ...setsTableItems],
        },
        [displayModes.BOXES]: {
          stats: boxesStats,
          table: boxesTableItems,
        },
        [displayModes.PALLETS]: {
          stats: palletsStats,
          table: palletsTableItems,
        },
      };
      return baseInfo;
    },
    [
      productsTableItems,
      boxesTableItems,
      palletsTableItems,
      setsTableItems,
      lineStatus,
      productsStats,
      boxesStats,
      palletsStats,
    ]
  );

  useEffect(() => {
    const sortedData = tabsInfo[selectedDisplayingMode].table
      .filter(item => item)
      .sort((a, b) => {
        const timeCompare = new Date(b.UTCTimestamp) - new Date(a.UTCTimestamp);
        return timeCompare !== 0 ? timeCompare : b.Id - a.Id;
      });

    setTableItems(sortedData);
    setStats(tabsInfo[selectedDisplayingMode].stats);
  }, [selectedDisplayingMode, tabsInfo]);

  useEffect(() => {
    resetTableFuncs.current = [setTableItems];
  }, [resetTableFuncs]);

  useEffect(() => {
    if (tableItems.length > 0) {
      const newItem = tableItems[0];
      if (!lastAddedItem || newItem.Id > lastAddedItem.Id) {
        setLastAddedItem(newItem);
      }
    }
  }, [tableItems]);

  const onChange = (e) => {
    setChecked(e.target.value);
  };

  const generateTableByFilter = () => {
    if (!tableItems?.length) return [];

    switch (checked) {
      case "all":
        return tableItems;
      case "valid":
        return (
          !!tableItems.length &&
          tableItems.filter(
            (data) =>
              ["SUCCESS"].includes(data.Status) || data.ProductLevel === 3
          )
        );

      case "notvalid":
        return (
          !!tableItems.length &&
          tableItems.filter((data) =>
            [
              "DUPLICATE",
              "PRODUCT",
              "MASK",
              "NOFOUND",
              "EXPIRED",
              "NETWORK",
            ].includes(data.Status)
          )
        );

      case "error":
        return (
          !!tableItems.length &&
          tableItems.filter((data) => ["NOREAD"].includes(data.Status))
        );

      case "dropout":
        return (
          !!tableItems.length &&
          tableItems.filter((data) => {
            if (selectedDisplayingMode === 1) return data.Status === "DROPOUT";
          })
        );
      default:
        return [];
    }
  };

  const generateRow = ({ index, style }) => {
    const item = generateTableByFilter()[index];
    return (
      <TableRow
        key={`resultTableRow-${index}`}
        showPhotos={showPhotos}
        data={item}
        style={style}
        isNewItem={item.Id === lastAddedItem?.Id}
      />
    );
  };

  return (
    <div className={s.results}>
      <div className={s.header}>
        <div className={s.filters}>
          <Radio
            checked={checked === "all"}
            label={`Все (${stats.total || 0})`}
            name="filter"
            value="all"
            onChange={onChange}
            status={codeStatuses.INITIAL}
            styles={{ marginBottom: 0 }}
          />
          <Radio
            checked={checked === "valid"}
            label={`Успешно (${stats.valid || 0})`}
            name="filter"
            value="valid"
            onChange={onChange}
            status={codeStatuses.VALID}
            styles={{ marginBottom: 0 }}
          />
          <Radio
            checked={checked === "notvalid"}
            status={codeStatuses.INVALID}
            label={`Невалиден (${stats.notValid || 0})`}
            name="filter"
            value="notvalid"
            onChange={onChange}
            styles={{ marginBottom: 0 }}
          />
          <Radio
            checked={checked === "error"}
            label={`Не считан (${stats.error || 0})`}
            status={codeStatuses.NOREAD}
            name="filter"
            value="error"
            onChange={onChange}
            styles={{ marginBottom: 0 }}
          />

          {selectedDisplayingMode === 1 && (
            <Radio
              checked={checked === "dropout"}
              label={`Брак (${stats.dropout || 0})`}
              status={codeStatuses.DROPOUT}
              name="filter"
              value="dropout"
              onChange={onChange}
              styles={{ marginBottom: 0 }}
            />
          )}
        </div>
      </div>
      <div className={s.table}>
        <div className={s.tableHeader}>
          <button type="button" className={clsx(s.key, s.icon)} />
          <button type="button" className={clsx(s.key, s.date)}>
            Дата и время
          </button>
          <button type="button" className={clsx(s.key, s.scanner)}>
            Сканер
          </button>
          <button type="button" className={clsx(s.key, s.status)}>
            Статус
          </button>
          <button type="button" className={clsx(s.key, s.code)}>
            Код
          </button>
          {/* <button type="button" className={clsx(s.key, s.photo)}>
            Фото
          </button> */}
        </div>
        <div className={s.tableContent}>
          <List
            height={300}
            itemSize={40}
            width="100%"
            itemCount={generateTableByFilter().length}
          >
            {generateRow}
          </List>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ResultsTable);
